
// Import React Components
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, FormControl, Alert } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';

function ForgotPasswordButton({ handleForgotPassword }) {
    return (
        <p style={{ margin: '0', fontSize: '14px', color: 'grey' }}>
            <button
                className='clickable'
                onClick={() => handleForgotPassword()}
                style={{ color: `#0066CC`, paddingLeft: '3px', border: 'none', background: 'none' }}
            >
                <u>Send another password-reset email...</u>
            </button>
        </p>
    );
}

// Create The Component
function ResetPassword() {
    // Grab Params
    const params = useParams();
    const { token } = params;

    // useState: set component state
    // const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState(null);

    // useEffect: Validation + Form Errors
    useEffect(() => {
        // Validate Email + Password + Verification + Form Overall
        let isPasswordValid = password.length >= 6;
        let doPasswordsMatch = (password === verifyPassword);

        setIsFormValid(isPasswordValid && doPasswordsMatch);
    }, [password, verifyPassword]);

    // Reset Password handler function
    const handleResetPassword = async (e) => {
        // console.log('Starting to Update Password');
        setIsUpdating(true);
        e.preventDefault();
        try {
            // make API call to update password
            let apiBaseUrl = config.url.API_URL;
            await axios.post(`${apiBaseUrl}/email/update-new-password`, { token, password });

            // Set States Following Successful Login
            setIsUpdating(false); setPassword(''); setVerifyPassword('');

            // Show Alert for 4 seconds
            setShowAlert(true);
            setTimeout(() => { setShowAlert(false); }, 4000);
        } catch (err) {
            setIsUpdating(false);
            // eslint-disable-next-line no-unused-expressions
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    // Push to /forgot-password
    const navigate = useNavigate();
    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    // Create Submit Button
    // let isEmailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
    const submitButton =
        (<div
            // className={`cbb-submit-button ${isEmailValid ? 'disabled' : ''} ${showAlert ? 'faded' : ''}`}
            className={`cbb-submit-button ${showAlert ? 'faded' : ''} ${!isFormValid ? 'disabled' : ''}`}
            style={{ width: '275px', textAlign: 'center' }}
            onClick={handleResetPassword}
        >
            {`${isUpdating ? 'Resetting...' : 'Reset Your Password'}`}
        </div>);

    // Create Email Form Input
    const passwordFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name='password'
            placeholder='Enter New Password'
        />);
    const verifyPasswordFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='password'
            value={verifyPassword}
            onChange={(e) => setVerifyPassword(e.target.value)}
            name='verify-password'
            placeholder='Verify New Password'
        />);

    return (
        <Container style={{ maxWidth: '100%' }}>
            <Container style={{ width: '275px' }}>
                <Row style={{ justifyContent: 'center', margin: '10px', marginTop: '40px' }}>
                    {passwordFormControl}
                </Row>
                <Row style={{ justifyContent: 'center', margin: '10px' }}>
                    {verifyPasswordFormControl}
                </Row>
                <Row style={{ justifyContent: 'center', margin: '10px' }}>
                    {submitButton}
                </Row>
                {password.length > 0 && password.length <= 6 &&
                    <p style={{ margin: '0 0 5px 0', fontSize: '0.95em', fontStyle: 'italic' }}>...password too short</p>
                }
                {password !== verifyPassword &&
                    <p style={{ margin: '0 0 5px 0', fontSize: '0.95em', fontStyle: 'italic' }}>...passwords do not match</p>
                }
                {showAlert &&
                    <Row style={{ justifyContent: 'center', margin: '10px' }}>
                        <Alert variant='success' className='animate-fade-away'>
                            Your password was reset
                        </Alert>
                    </Row>
                }
                {(error && error.length > 0) &&
                    <Row style={{ justifyContent: 'center', margin: '10px' }}>
                        <Alert className='modal-alert' variant={'danger'}>
                            {error}
                        </Alert>
                    </Row>
                }
                {error === 'Invalid reset password token' &&
                    <ForgotPasswordButton handleForgotPassword={handleForgotPassword} />
                }
            </Container>
        </Container>
    );
}

export default ResetPassword;
