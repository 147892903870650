// https://redketchup.io/image-resizer for image sizing
// https://onlinepngtools.com/convert-jpg-to-png for adding transparency to pngs
// https://tinypng.com/ for minifying pngs

// Import React Components
import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';

// CBB Components
import SectionHeader from '../../components/headers/SectionHeader';
import LandingPageCard from '../../components/uiUxItems/LandingPageCard';
import imageUrls from '../../images/gcsImages';


function StatsLanding() {
    // useContext + extract variables from context
    const { userTier, userData, defaultCompetition } = useContext(GlobalContext);
    const isLoggedIn = userData.user ? true : false;
    const divisionId = isLoggedIn ? userData.user.team.divisionId : 1;
    const competitionId = defaultCompetition.value;
    const portalCompetitionId = userData?.user?.team?.gender === 'MALE' ? 36046 : 36045 || 36046; //                   update in Jan/Feb when transitioning to new season's transfer portal
    const draftCompetitionId = userData?.user?.team?.gender === 'MALE' ? 38409 : 38529 || 38409; //                    update in Jan/Feb after hard-coding draft prospects for new draft
    const nilCompetitionId = 38409; //                                                                                 update in Jan/Feb as this is when coaches would likely want to see the switch from last year's NIL predictions
    const tourneyCompId = isLoggedIn ? (userData?.user?.team?.gender === 'MALE' ? 36046 : 36045) : 36046; //           update in March before start of march madness 68-team tournament
    const tournamentId = isLoggedIn ? (userData?.user?.team?.gender === 'MALE' ? 2225 : 2227) : 2225; //               update in March before start of march madness 68-team tournament

    // the different stats landing page cards to map over
    const cardData = [
        {
            header: 'Division Stats',
            subHeader: 'Our full division leaderboards for players and teams. Sort and filter over the entire country.',
            subHeader2: 'On free tier, team leaderboards only.',
            src: imageUrls.statsDivisionStats,
            path: `/stats/${competitionId}/division/d${divisionId}/team-box`,
            tierRequired: 1
        }, {
            header: 'Conference Stats',
            subHeader: 'A rundown of the best conferences. These are stats aggregated across the entire conference.',
            src: imageUrls.statsConferenceStats,
            path: `/stats/conference-home/${competitionId}`,
            tierRequired: 1
        }, {
            header: 'Draft Prospects',
            subHeader: 'A rundown of the top 50 - 100 WNBA and NBA draft prospects each season.',
            src: imageUrls.statsDraftProspects,
            path: `/stats/${draftCompetitionId}/draft/player-box`,
            tierRequired: 1
        }, {
            header: 'Tournament Stats',
            subHeader: 'Tournament-specific Stats: Detailed statistics for individual tournaments.',
            subHeader2: 'On free tier, a demo for one tournament only.',
            src: imageUrls.statsTournamentStats,
            path: `/stats/${tourneyCompId}/tournaments/${tournamentId}/team-box`,
            tierRequired: 1
        }, {
            header: 'Transfer Portal',
            subHeader: 'Active Transfers: Stats and details on players currently in the transfer portal.',
            src: imageUrls.statsTransferPortal,
            path: `/stats/${portalCompetitionId}/portal/overview`,
            tierRequired: 3
        }, {
            header: 'Transfer History',
            subHeader: `An overview of teams' performance in the transfer portal over the years. Additionally, who were the best incoming & outgoing transfers.`,
            src: imageUrls.statsTransferHistory,
            path: `/stats/${competitionId}/transfers/${divisionId}/overview`,
            tierRequired: 3
        }, {
            header: 'Live Game Stats',
            subHeader: `Websocket to connect to live games. Includes player & team box score stats, shot charts, and more, all live as the game progresses.`,
            src: imageUrls.statsLiveGameStream,
            path: `/streaming/${competitionId}`,
            tierRequired: 3
        // }, {
        //     header: 'NIL Predictions',
        //     subHeader: `Predict NIL values for players based on their stats and transfer history.`,
        //     src: imageUrls.statsNilPredictions,
        //     path: `/stats/${nilCompetitionId}/nil/player-box`,
        //     tierRequired: 3
        }
    ];


    // And return
    return (
        <Container className='shadow-container'>
            <SectionHeader
                title='CBB Stats Hub'
                subtitle='Comprehensive Statistics for In-Depth Analysis'
                bodyStyles={{ maxWidth: 1200, paddingBottom: 20, paddingTop: 35 }}
                titleStyles={{ fontSize: 'calc(1.2vw + 21px)' }}
            />

            <Container className='padded-inner-container' style={{ maxWidth: 1200 }}>
                <Row style={{ margin: '15px 0px 30px', maxWidth: 975 }}>
                    <p style={{ fontSize: 'calc(0.08vw + 15px)' }}>
                        Stats Hub provides comprehensive statistical resources including division leaderboards, conference-aggregated comparisons, draft prospect leaderboards, individual tournament breakdowns, as well as insights into the transfer portal. Whether you're tracking top performers, analyzing conference strengths, or diving into transfer impacts, our stats hub offers the depth and breadth needed for comprehensive scouting & analysis.
                    </p>
                </Row>

                <Row style={{ padding: 0, margin: '20px -10px 0px -10px' }}>
                    {cardData.map((card, index) => (
                        <Col key={index} xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 3, offset: 0 }} style={{ marginBottom: 25, padding: '0px 10px' }}>
                            <LandingPageCard
                                header={card.header}
                                subHeader={card.subHeader}
                                subHeader2={card.subHeader2}
                                src={card.src}
                                path={card.path}
                                tierRequired={card.tierRequired}
                                disabled={userTier.value < card.tierRequired}
                                config={card.config}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
}

export default StatsLanding;
