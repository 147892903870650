
// Import React Components
import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../context/GlobalContext';

// CBB Components
import imageUrls from '../../images/gcsImages';
import LandingPageCard from '../../components/uiUxItems/LandingPageCard';
import SectionHeader from '../../components/headers/SectionHeader';

function ToolsLanding() {
    // gotta grab for correct scoreboard path
    const { userData, userTier, defaultCompetition } = useContext(GlobalContext);
    const isLoggedIn = userData.user ? true : false;
    const competitionId = defaultCompetition.value;
    const divisionId = isLoggedIn ? userData.user.team.divisionId : 1;
    const latestDate = (new Date()).toISOString().slice(0, 10).replace(/-/g, '');
    const utv = userTier.value;
    const isFreeTier = userTier.value === 1;

    // tier == tier required to  access too.
    const cardData = [
        {
            header: `Shot Charts`,
            subHeader: `Visualize and analyze shooting performance with customizable shot charts for teams and individual players.`,
            subHeader2: utv === 1 ? `On free tier, shot charts are limited to teams.` : ``,
            src: imageUrls.toolShotChartsHero,
            path: '/tools/shot-charts',
            tierRequired: 0
        }, {
            header: `Team Scatter Plots`,
            subHeader: `Build scatter plots that showcase team performance across various metrics, enabling in-depth analysis league-wide.`,
            subHeader2: utv === 1 ? 'On free tier, limited to latest season.' : ``,
            src: imageUrls.toolTeamScatterHero,
            path: '/tools/team-scatter',
            tierRequired: 0
        }, {
            header: `Player Scatter Plots`,
            subHeader: `The same as Team Scatter Plots, but for players.`,
            src: imageUrls.toolPlayerScatterHero,
            path: '/tools/player-scatter',
            tierRequired: 0
        }, {
            header: 'NCAA Scoreboard',
            subHeader: `Stay updated with real-time scores across the league using our interactive daily scoreboard, and gain insights from a chosen day's performance.`,
            src: imageUrls.toolScoreboardHero,
            path: `/tools/scoreboard/${competitionId}/d${divisionId}/${latestDate}`,
            tierRequired: 0
        }, {
            header: `Bar Charts Builder`,
            subHeader: `Compare player and team statistics visually, using versatile and customizable bar charts tailored to your preferred metrics.`,
            subHeader2: utv === 1 ? 'On free tier, a limited demo only.' : ``,
            src: imageUrls.toolBarChartsHero,
            path: '/tools/bar-charts',
            tierRequired: 1
        }, {
            header: `4x Comparisons`,
            subHeader: 'Do side-by-side comparisons of up to players or teams simultaneously.',
            subHeader2: utv === 1 ? 'On free tier, comparisons are limited to 2x teams.' : ``,
            src: imageUrls.toolComparisonsHero,
            path: '/tools/comparisons',
            tierRequired: 1
        }, {
            header: `Game Deciding Shots`,
            subHeader: 'See which teams and players shoot the best on + have made the most game tying and go ahead shots.',
            subHeader2: utv === 1 ? 'On free tier, a limited demo only.' : ``,
            src: imageUrls.toolTyingGoAheadHero,
            config: { imgStyle: { padding: 0 } },
            path: `/tools/game-deciding-shots/${competitionId}/d${divisionId}`,
            tierRequired: 1
        }, {
            header: `Streaks & Droughts`,
            subHeader: 'Who has the longest uninterrupted scoring streaks and scoring droughts? + stats on streaks',
            subHeader2: utv === 1 ? 'On free tier, some functionality is limited.' : ``,
            src: imageUrls.toolStreaksHero,
            config: { imgStyle: { padding: 0 } },
            path: `/tools/scoring-streaks/${competitionId}/d${divisionId}`,
            tierRequired: 1
        }, {
            header: `DIY Shot Charts`,
            subHeader: 'Build shot charts from scratch using practice data, helping to monitor player progress towards improving game strategies.',
            subHeader2: utv === 1 ? 'On free tier, a limited demo only.' : ``,
            src: imageUrls.toolDiyShotChartsHero,
            path: '/tools/diy-shot-charts',
            tierRequired: 1
        // }, {
        //     header: `Game Calculator ${utv === 1 ? '[Limited]' : ''}`,
        //     subHeader: `Predict match results using player ratings and customizable minute distributions. ${isFreeTier ? 'Demo on Free Tier.' : ''}`,
        //     src: imageUrls.toolPlayerRatings,
        //     path: '/tools/game-calculator',
        //     tierRequired: 1
        }, {
            header: 'Regression Analysis',
            subHeader: 'Perform regression analyses on game and season stats to identify factors most predictive of offensive and defensive ratings.',
            subHeader2: utv === 1 ? 'On free tier, a limited demo only.' : ``,
            src: imageUrls.toolRegressionHero,
            path: '/tools/model-fitting',
            tierRequired: 1
        }, {
            header: `Game Explorer`,
            subHeader: `Search through individual player-game and team-game performances - see everything from triple doubles, to most points scored in a game, and more.`,
            src: imageUrls.toolGameStatsLeaderboard,
            path: `/tools/game-explorer/${competitionId}/d${divisionId}`,
            tierRequired: 1
        }
    ];

    return (
        <Container className='shadow-container'>
            <SectionHeader
                title='CBB Analytics Toolbox'
                subtitle='Customizable Tools for Deep Dives and Actionable Analytics'
                bodyStyles={{ maxWidth: 1200, paddingBottom: 20, paddingTop: 35 }}
                titleStyles={{ fontSize: 'calc(1.2vw + 21px)' }}
            />

            <Container className='padded-inner-container' style={{ maxWidth: 1200 }}>
                <Row style={{ margin: '15px 0px 30px', maxWidth: 975 }}>
                    <p style={{ fontSize: 'calc(0.08vw + 15px)' }}>
                        Analytics Toolbox offers a range of tools for coaching staffs, media, and fans. Our shot charts builders, customizable scatter plot builders, and other tools let you create graphics and analyze player and team performance in depth. Check out the different options to find the analysis that suit your needs.
                        <strong style={{ paddingLeft: 4, color: 'darkgreen' }}>User Tier now receives full access to this entire section.</strong>
                    </p>
                </Row>
                <Row style={{ padding: 0, margin: '20px -10px 0px -10px' }}>
                    {cardData.map((card, index) => (
                        <Col key={index} xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }} style={{ marginBottom: 25, padding: '0px 10px' }}>
                            <LandingPageCard
                                header={card.header}
                                subHeader={card.subHeader}
                                subHeader2={card.subHeader2}
                                src={card.src}
                                path={card.path}
                                tierRequired={card.tierRequired}
                                disabled={card.tierRequired > userTier.value}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
}

export default ToolsLanding;
