
// Copy design from here - https://api.id.me/en/registration/new
// Paid Tier: Order complete. pause, login, send to homepage.

// React Stuff
import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import Select from 'react-select';
import { Link, useSearchParams } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Alert, Button, FormControl, Row, Container } from 'react-bootstrap';
import { FaLock, FaCheckCircle } from 'react-icons/fa';

// CBB Data Fetching
import GlobalContext from '../../context/GlobalContext';

// CBB Utils
import HelmetComponent from '../../utils/HelmetUtils';
import config from '../../config';
import CBBSelect from '../../components/selects/CBBSelect';
import LoadingSpinner from '../../components/uiUxItems/LoadingSpinner';
import Logo from '../../components/uiUxItems/Logo';
import { signupSelectStyles } from '../../utils/ReactSelectStyles';
import imageUrls from '../../images/gcsImages';

// CBB Stripe
import CBBCardElement from './stripe/buttons/CBBCardElement';
import PromoCodeInputForm from './stripe/buttons/PromoCodeInputForm';
const mappedTiers = {
    default: { value: 0, label: 'Select an Access Tier' },
    free: { value: 1, label: 'Free Tier: Unlimited Free Access' },
    user: { value: 2, label: 'User Tier: Fans, Writers, D-II & D-III Teams' },
    pro: { value: 4, label: 'Pro Tier: D-I Teams Full Access' }
};


function SignupPage() {
    // query parameters - set starting state for "userTier" based on the query paramter "tier"
    const [queryParameters] = useSearchParams();
    const queryTier = queryParameters.get('tier') ?? 'default';
    const referralCode = queryParameters.get('referral') ?? null;
    const initialUserTier = referralCode
        ? { value: 2, label: 'User Tier: Fans, Writers, D-II & D-III Teams' }
        : mappedTiers[queryTier];

    // useContext + useParams
    const { adminTier, teamInfosObj, conferenceInfosObj } = useContext(GlobalContext);
    const teamInfos = Object.values(teamInfosObj); // from object back to array
    const isLoading = teamInfos.length === 0; //  || conferenceInfos.length === 0;
    const isAdmin = adminTier.value === 2;

    // Stripe Hooks
    const elements = useElements();
    const stripe = useStripe();

    // useState: Setting Page State
    // user info
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verification, setVerification] = useState('');
    const [userTypeOther, setUserTypeOther] = useState('');
    // team, tier info
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [userTier, setUserTier] = useState(initialUserTier);
    const [userType, setUserType] = useState(null);
    const [price, setPrice] = useState({ value: 30, label: '$30 / Month', text: '$30 / month (billed monthly)' });
    // referral code
    const [referral, setReferral] = useState({ promoCode: null, code: referralCode, discount: 0, color: 'black', message: '' });
    // validation
    // const [signupError, setSignupError] = useState(null);
    const [signupError, setSignupError] = useState({ account: null, stripe: null, email: null });
    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isVerificationValid, setIsVerificationValid] = useState(false);
    const [formErrors, setFormErrors] = useState({ email: '', password: '', verification: '', firstName: '', lastName: '', overall: '' });
    const [isFormValid, setIsFormValid] = useState(false);
    // page transitions
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [didSignUp, setDidSignUp] = useState(false);
    const [isCardComplete, setIsCardComplete] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [generatedPassword, setGeneratedPassword] = useState('');
    // console.log('SignupPage State: ', { conferencesInfo, teamsInfo, userData, email, password, verification, isEmailValid, isPasswordValid, isVerificationValid, formErrors, isFormValid, selectedGender, selectedTeam, isSigningUp, didSignUp });

    // derived from useState
    const rawConf = conferenceInfosObj && selectedTeam ? conferenceInfosObj[selectedTeam.conferenceId] : null;
    const selectedConf = !rawConf ? null : {
        value: rawConf.conferenceId,
        label: rawConf.conferenceLongName,
        abb: rawConf.conferenceShortName
    };

    // handle credit card
    const handleCardChange = (e) => {
        setIsCardComplete(e.complete);
    };

    // set state with test-user information
    const setTestUserStates = () => {
        setFirstName('Test');
        setLastName('User');
        setEmail('test@gmail.com');
        setPassword('password');
        setVerification('password');
        setUserType({ value: 'team', label: 'Work for a college basketball team' });
        setSelectedGender({ value: 'MALE', label: `Men's NCAA Basketball` });
        setSelectedTeam({ value: 105556, label: 'Stanford Cardinal', teamId: 105556, teamMarket: 'Stanford', teamName: 'Cardinal', gender: 'FEMALE', divisionId: 1, conferenceId: 74 });
        setUserTier({ value: 2, label: 'User Tier: Fans, Writers, D-II & D-III Teams' });
    };
    const testStatesButton =
        (<div
            className='cbb-button'
            style={{ marginRight: 0, maxWidth: 375, border: '1px solid #777777', height: 34, color: '#222222' }}
            onClick={() => setTestUserStates()}
        >
            <span>Set Test States</span>
        </div>);

    // useEffect to check for valid form completion as user updates form
    useEffect(() => {
        // Validate Email + Password + Verification + Form Overall
        let isCurrentFirstNameValid = firstName.length >= 2;
        let isCurrentLastNameValid = lastName.length >= 2;
        let isCurrentEmailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
        let isCurrentPasswordValid = password.length >= 6;
        let doPasswordsMatch = (password === verification);
        let userTypeOtherValid = userTypeOther.length >= 3;

        setIsEmailValid(isCurrentEmailValid);
        setIsPasswordValid(isCurrentPasswordValid);
        setIsVerificationValid(doPasswordsMatch);
        setIsFirstNameValid(isCurrentFirstNameValid);
        setIsLastNameValid(isCurrentLastNameValid);
        setIsFormValid(
            isCurrentFirstNameValid && isCurrentLastNameValid && // valid names
            isCurrentEmailValid && isCurrentPasswordValid && doPasswordsMatch && // valid email & password
            selectedConf && selectedTeam && // valid default team
            userType && (userType.value !== 'other' || userTypeOtherValid) && // valid user type
            userTier.value > 0 && ([1, 2].includes(userTier.value) || isAdmin) && // valid user tier
            (!isAdmin && userTier.value === 2 ? isCardComplete : true) // cc payment filled out for tier 2
        );

        // Set Form Errors
        let latestFormErrors = formErrors;
        latestFormErrors.firstName = isCurrentFirstNameValid ? '' : 'Name is too short';
        latestFormErrors.lastName = isCurrentLastNameValid ? '' : 'Name is too short';
        latestFormErrors.email = isCurrentEmailValid ? '' : 'Email is invalid';
        latestFormErrors.password = isCurrentPasswordValid ? '' : 'Password is too short';
        latestFormErrors.verification = doPasswordsMatch ? '' : 'Passwords do not match';
        latestFormErrors.overall = isFormValid ? '' : 'Form not filled out completely, fill in the missing fields above...';
        setFormErrors(latestFormErrors);
    }, [firstName, lastName, email, password, verification, selectedConf, selectedTeam, userType, userTypeOther, userTier, isCardComplete]);


    // signup helpers for account creation
    const createUserAccount = async () => {
        // create user object from form elements
        let newUserParams = {
            firstName: firstName.trim(),
            lastName: lastName.trim(), // name
            email, password, verification, // email and password
            conference: selectedConf, team: selectedTeam, gender: selectedGender, // default team
            userType: userType.value !== 'other' ? userType : { value: 'other', label: userTypeOther }, // user type
            userTier, // object w/ value & label (tier 2)
            sendEmail // boolean for admin to choose to send email verification
        };

        // register the user in CBB database
        let apiBaseUrl = config.url.API_URL;
        let registerResponse;
        if (isAdmin) { // admin register - (ability to create tier 3+ users without email verification)
            const token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');
            const authHeader = { headers: { 'x-auth-token': token } }; // pass toke, authHeader to authenticate as admin
            registerResponse = await Axios.post(`${apiBaseUrl}/users/admin-register`, newUserParams, authHeader);
        } else { // basic user register
            registerResponse = await Axios.post(`${apiBaseUrl}/users/register`, newUserParams);
        }

        // extract from registerResponse
        const isRegisterSuccessful = registerResponse.status === 200;
        const userToken = registerResponse?.data?.token || null;
        const newUser = registerResponse?.data?.user || null;

        // and return all
        return { registerResponse, isRegisterSuccessful, userToken, newUser };
    };

    // signup helpers for stripe subscription
    const createStripeSubscription = async ({ cardElement, userToken }) => {
        // cardElement:     the card element with credit card info
        // userToken:       from account register, this token is needed in header to create subscription

        // create payment method in stripe using inputted cardElement
        const paymentMethodReq = await stripe.createPaymentMethod({ type: 'card', card: cardElement });
        if (paymentMethodReq.error) { // handle bad payment method
            cardElement.update({ disabled: false });
            return;
        }

        // Use payment method to create subscription
        let paymentObject = {
            useExistingCard: false,
            newPaymentMethod: paymentMethodReq.paymentMethod.id,
            priceKey: price.value, // 30,85,160,299
            promoCode: referral.promoCode, // okay
            referralCode: referral.code
        };

        // And create subscription
        try {
            const apiBaseUrl = config.url.API_URL;
            const authHeader = { headers: { 'x-auth-token': userToken } };
            const stripeResponse = await Axios.post(`${apiBaseUrl}/stripe/payment-subscriptions`, paymentObject, authHeader); // we should be handling
            // eslint-disable-next-line consistent-return
            return stripeResponse;
        } catch (err) {
            if (err.response) {
                throw err.response;
            } else { throw err; }
        }
    };
    const handleCreateStripeSubscriptionError = async ({ err, cardElement, newUser }) => {
        // console.log('handleCreateStripeSubscriptionError: ', err);

        // if stripe subscription error, handle error, then delete user in CBB database (and then re-enable credit card field)
        const errorType = err?.data?.raw?.type || err?.data?.type || 'Card Error';
        const errMessage = err?.data?.raw?.message || err?.data?.message || 'We ran into an issue creating a Stripe subscription for your account';
        setSignupError({ ...signupError, stripe: `${errorType}: ${errMessage}` });
        setTimeout(() => setSignupError({ ...signupError, stripe: null }), 15000);
        try {
            // eslint-disable-next-line no-unused-vars
            const deleteUserResp = await Axios.post(`${config.url.API_URL}/users/delete-new-user`, { email: newUser.email });
            cardElement.update({ disabled: false });
        } catch (err2) {
            console.error('Error deleting user: ', err2);
        }
    };

    // handle user signup, orchestration of helper handler functions above
    const handleSignUp = async (e) => {
        e.preventDefault();
        setIsSigningUp(true);

        // and try stuff
        try {
            // 0: handle invalid form, disable cardElement so no more changes can be made
            if (!isFormValid) { return; }
            let cardElement = null;
            if (userTier.value === 2) {
                cardElement = elements.getElement('card');
                cardElement.update({ disabled: true });
            }

            // 1: create user in CBB database object from form elements
            const { isRegisterSuccessful, userToken, newUser } = await createUserAccount();

            // 2: create stripe payment subscription, handle errors if subscription creation fails
            let stripeResponse;
            const trySubscription = isRegisterSuccessful && userTier.value === 2 && !isAdmin;
            if (trySubscription) {
                try {
                    stripeResponse = await createStripeSubscription({ cardElement, userToken });
                } catch (err) {
                    await handleCreateStripeSubscriptionError({ err, cardElement, newUser });
                }
            }

            // 3: send verification email
            let signupSuccessful = isRegisterSuccessful && (trySubscription ? stripeResponse?.status === 200 : true);
            if (signupSuccessful && sendEmail) {
                try {
                    const apiBaseUrl = config.url.API_URL;
                    await Axios.post(`${apiBaseUrl}/users/send-verification-email`, { user: newUser });
                } catch (err) {
                    console.log('error sending email: ', err);
                }
            }

            // 4: facebook pixel tracking for signups (for non-admin, & only if window.fbq function exists)
            const fbTrackSignup = !isAdmin && typeof window !== 'undefined' && typeof window.fbq === 'function';
            if (signupSuccessful && fbTrackSignup) {
                switch (userTier.value) {
                    case 1: window.fbq('track', 'Lead'); break;
                    case 2: window.fbq('track', 'StartTrial'); break;
                    default: console.log('hmmm');
                }
            }

            // 5: Set States Following Successful Login
            setIsSigningUp(false);
            setDidSignUp(signupSuccessful);
        } catch (err) {
            console.log('Signup Error: ', err);
            setIsSigningUp(false);
            const errMessage = err && err.response && err.response.data && err.response.data.msg || 'Sign up error, sign up not successful. Please try again.';
            setSignupError({ ...signupError, other: errMessage });
            setTimeout(() => setSignupError({ ...signupError, other: null }), 15000);
        }
    };

    const generatePassword = () => {
        console.log('Generate password function called');
        if (!selectedTeam || !selectedTeam.teamMarket) {
            return;
        }

        try {
            console.log('Selected team:', selectedTeam);
            const teamName = selectedTeam.teamName.replace(/\s+/g, '').toLowerCase();
            const allowedChars = 'abcdefghjkmnpqrstuvwxyz234567892345678923456789';
            let newPassword = teamName;

            // next digit should be a number that isn't 0 or 1
            let nextDigit = Math.floor(Math.random() * 8) + 2;
            newPassword += nextDigit;

            while (newPassword.length < 18) {
                const randomChar = allowedChars[Math.floor(Math.random() * allowedChars.length)];
                newPassword += randomChar;
            }

            setGeneratedPassword(newPassword);
            setPassword(newPassword);
            setVerification(newPassword);
        } catch (error) {
            console.error('Error generating password:', error);
            // alert('An error occurred while generating the password. Please try again.');
        }
    };


    // Payment CC Field
    // =================
    let showPaymentForm = !isAdmin && userTier.value === 2;
    let paymentForm =
        (<div style={{ marginTop: 10, maxWidth: 320, display: showPaymentForm ? 'block' : 'none' }}>

            {/* Text Above Payment Box */}
            <Row style={{ margin: 0, marginTop: 6, justifyContent: 'space-between', alignItems: 'center', fontSize: '0.9em', color: '#444' }}>
                <span>Payment Information</span>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 3 }}>
                    <span style={{ marginRight: 5 }}>Secure</span>
                    <FaLock size={12} stroke='#444' />
                </div>
            </Row>

            {/* Fill Out Credit Card Information */}
            <Row style={{ margin: 0, marginBottom: 5 }}>
                <CBBCardElement onChange={handleCardChange} />
            </Row>

            {/* Note of assurance */}
            <Row style={{ margin: '-3px 0px 5px 0px' }}>
                <span style={{ fontSize: '0.85em', fontStyle: 'italic', lineHeight: 1.3 }}>
                    Subscription includes 1-week free trial. <strong style={{ color: 'darkgreen' }}>Card is not charged until after trial is completed.</strong> Cancel anytime from account settings!
                </span>
            </Row>
        </div>);


    // Selects & Form Elements
    // ========================
    let errorTextStyles = { margin: '0 0 5px 0', fontSize: '0.95em', fontStyle: 'italic', lineHeight: 1.3 };

    // First Name Form Element
    const firstNameFormControl = (<>
        <FormControl
            disabled={isSigningUp}
            className='modal-input'
            type='text'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            name='firstname'
            placeholder='First Name'
        />
        {firstName.length > 0 && !isFirstNameValid &&
            <p style={errorTextStyles}>{formErrors.firstName}</p>
        }
    </>);

    // Last Name Form Element
    const lastNameFormControl = (<>
        <FormControl
            disabled={isSigningUp}
            className='modal-input'
            type='text'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            name='lastname'
            placeholder='Last Name'
        />
        {lastName.length > 0 && !isLastNameValid &&
            <p style={errorTextStyles}>{formErrors.lastName}</p>
        }
    </>);

    // Email Form Element
    const emailFormControl = (<>
        <FormControl
            disabled={isSigningUp}
            className='modal-input'
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
            name='email'
            placeholder='E-mail'
        />
        {email.length > 0 && !isEmailValid &&
            <p style={errorTextStyles}>{formErrors.email}</p>
        }
    </>);

    // For Admin, Generate Password Form Element
    const adminGeneratePasswordForm = (<>
        {password.length > 0 && !isPasswordValid &&
            <p style={errorTextStyles}>{formErrors.password}</p>
        }
        <Button
            onClick={generatePassword}
            disabled={!selectedTeam}
            style={{ marginTop: '10px', marginBottom: '10px' }}
        >
            {!selectedTeam ? 'Select Team First' : 'Generate Password'}
        </Button>
        {generatedPassword && (
            <div style={{ marginBottom: '10px', wordBreak: 'break-all' }}>
                <strong>Generated Password:</strong> {generatedPassword}
            </div>
        )}
    </>);

    const passwordFormControl = (<>
        <FormControl
            disabled={isSigningUp}
            className='modal-input'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value.trim())}
            name='password'
            placeholder='Password'
        />
    </>);

    // Verify Password Form Element
    const verifyPasswordFormControl = (<>
        <FormControl
            disabled={isSigningUp}
            className='modal-input'
            type='password'
            value={verification}
            onChange={(e) => setVerification(e.target.value.trim())}
            name='verification'
            placeholder='Verify Password'
        />
        {verification.length > 0 && !isVerificationValid &&
            <p style={errorTextStyles}>{formErrors.verification}</p>
        }
    </>);

    // User Type Other Select
    const userTypeOtherFormControl = (<>
        <FormControl
            disabled={isSigningUp}
            className='modal-input'
            type='text'
            value={userTypeOther}
            onChange={(e) => setUserTypeOther(e.target.value)}
            name='usertype'
            placeholder='In a few words, describe your use case'
        />
    </>);

    let promoCodeInputForm =
        (<PromoCodeInputForm
            referral={referral}
            setReferral={setReferral}
            isDisabled={isSigningUp}
        />);

    // Toggle Yes/No Send Email (Admin Button)
    const sendEmailButton =
        (<button
            className='cbb-button'
            role='button'
            tabIndex={0}
            style={{ marginRight: 0, maxWidth: 375, border: '1px solid #777777', height: 34, color: '#222222' }}
            onClick={() => setSendEmail(old => !old)}
        >
            <span>
                {`${sendEmail ? 'Yes Send Email' : 'Not Sending Email'}`}
            </span>
        </button>);

    // League Select
    let genderOptions = [
        { value: 'MALE', label: `Men's NCAA Basketball` },
        { value: 'FEMALE', label: `Women's NCAA Basketball` }];
    const leagueSelect =
        (<Select
            styles={signupSelectStyles}
            placeholder={'Select League'}
            isDisabled={isSigningUp}
            value={selectedGender}
            onChange={(e) => {
                setSelectedGender(e);
                setSelectedTeam(null);
            }}
            options={genderOptions}
        />);

    // Team Select
    let teamOptions = teamInfos
        .filter(team => selectedGender ? team.gender === selectedGender.value : true)
        .filter(team => selectedTeam && team.teamId === selectedTeam.value ? false : true)
        .sort((a, b) => a.teamMarket < b.teamMarket ? -1 : 1)
        .map(team => {
            return {
                value: team.teamId,
                label: `${team.teamMarket} ${team.teamName}`,
                teamId: team.teamId,
                teamMarket: team.teamMarket,
                teamName: team.teamName,
                gender: team.gender,
                conferenceId: team.conferenceId,
                divisionId: team.divisionId
            };
        });

    const ncaaTeamSelect =
        (<div style={{ marginTop: 3 }}>
            <Select
                styles={signupSelectStyles}
                placeholder={'Select Team'}
                isDisabled={isLoading || teamOptions.length === 0 || isSigningUp || selectedGender === null}
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e)}
                options={teamOptions}
            />
        </div>);

    // User Type Select
    const userTypeOptions = [
        { value: 'team', label: 'Work for a college basketball team' },
        { value: 'nba', label: 'Work for an NBA team' },
        { value: 'media', label: 'College basketball writer, journalist' },
        { value: 'fan', label: 'Fan interested in basketball analytics' },
        { value: 'other', label: 'Other' }];
    const userTypeSelect =
        (<Select
            styles={signupSelectStyles}
            placeholder={'Which best describes you?'}
            isDisabled={isLoading || isSigningUp}
            value={userType}
            onChange={e => setUserType(e)}
            options={userTypeOptions}
        />);

    // User Tier Select
    let userTierOptions = [
        ...(isAdmin ? [{ value: 0, label: 'No Access: Not Verified' }] : []),
        { value: 1, label: 'Free Tier: Unlimited Free Access' },
        { value: 2, label: 'User Tier: Fans, Writers, D-II & D-III Teams' },
        ...(isAdmin ? [{ value: 2, label: 'User Tier: Free Subscription', free: true }] : []),
        ...(isAdmin ? [{ value: 3, label: 'Pro Tier: D-I Teams Full Access (Trial)' }] : []),
        { value: 4, label: 'Pro Tier: D-I Teams Full Access' },
        ...(isAdmin ? [{ value: 4, label: 'Pro Tier: Free Subscription', free: true }] : []),
        ...(isAdmin ? [{ value: 5, label: 'Pro Tier: Super User Tier' }] : []),
        ...(isAdmin ? [{ value: 9, label: 'Admin Tier' }] : [])
    ];

    const userSetTierSelect =
        (<Select
            styles={signupSelectStyles}
            placeholder='Select an Access Tier'
            isDisabled={isLoading || isSigningUp}
            value={userTier}
            onChange={e => setUserTier(e)}
            options={userTierOptions}
        />);

    // 1-Month, 3-Month, 6-Month, 12-Month Options
    let priceSelect =
        (<CBBSelect
            selectType='subscriptionPackages'
            value={price}
            setValue={setPrice}
            stylesType='small'
            isDisabled={isSigningUp}
        />);
    // ==========

    // Signup Button
    // ===============
    const signupButton = (<>
        <Button
            className={`signup-button ${!isFormValid && 'disabled'}`}
            style={{ fontWeight: 700, maxWidth: 375, color: 'white', fontSize: '0.95em' }}
            variant={!isFormValid ? 'danger' : 'submit'}
            disabled={!isFormValid || isSigningUp}
            onClick={handleSignUp}
            block
        >
            {(userTier.value === 4 && !isAdmin) ? 'Email for Quote'
                : (!isFormValid ? 'Signup form not fully complete'
                    : (userTier.value === 1 ? 'Sign Up: Free Tier'
                        : (isAdmin ? 'Admin Signup Valid'
                            : (userTier.value === 2 ? `Sign Up: User Tier (${price.label})` : 'Issue with signup form'))))}
        </Button>
        {!isFormValid && userTier.value !== 4 &&
            <p style={errorTextStyles}>{formErrors.overall}</p>
        }
    </>);

    // Team & Conf Logos
    // ==================
    let confLogo = (
        <Logo
            style={{ width: 100 }}
            ptgc='conference'
            conferenceId={selectedConf && selectedConf.value || null}
        />);

    let teamLogo = (
        <Logo
            style={{ width: 100, height: 100 }}
            ptgc='team'
            teamId={selectedTeam && selectedTeam.value || null}
        />);

    // Section Text
    // =============
    let accountCredentialsText =
        (<p style={{ lineHeight: 1.3, fontSize: '1.05em' }}>
            <span style={{ fontWeight: 700, color: '#0066CC' }}>1. Account Credentials - </span>
            Email and password are your login credentials. After signing up, we will send an
            email to verify your email address.
        </p>);

    let teamConfSelectText =
        (<p style={{ margin: 0, lineHeight: 1.3, fontSize: '1.05em' }}>
            <span style={{ fontWeight: 700, color: '#0066CC' }}>2. Team Select - </span>
            Select a primary team for your account.
        </p>);

    let nickEmailLink = <a style={{ color: '#0066CC' }} href={`mailto:nick@cbbanalytics.com?subject=CBB Analytics Quote for Pro Tier`}>nick@cbbanalytics.com</a>;
    let nickEmailLink2 = <a style={{ color: '#F3F3F3', textDecoration: 'underline', fontWeight: 700 }} href={`mailto:nick@cbbanalytics.com?subject=CBB Analytics Further Assistance`}>nick@cbbanalytics.com</a>;
    let accountTierText =
        (<p style={{ margin: 0, lineHeight: 1.3, fontSize: '1.05em' }}>
            <span style={{ fontWeight: 700, color: '#0066CC' }}>3. Account Access - </span>
            Select an account access tier.
        </p>);

    let accountTierStyles = { margin: 0, lineHeight: 1.3, fontSize: '1.05em' };
    let accountTierInfoText = (<>
        {userTier.value === 1 && <p style={accountTierStyles}>Free Tier provides limited access to stats for the default team selected, as well as access to a few other pages on the website. Free Tier is meant to showcase the platform for anyone considering a User Tier or Pro Tier subscription, but is otherwise fairly limited.</p>}
        {userTier.value === 2 && <p style={accountTierStyles}>User Tier provides full access to basic and premium D-II and D-III stats, and access to basic D-I stats for all teams. User Tier is designed for fans, beat writers, sports bettors, and D-II and D-III coaching staffs.</p>}
        {userTier.value === 4 && <p style={accountTierStyles}>Pro Tier includes full access to the platform plus several advanced features designed specifically for coaching staffs. Pro Tier is primarily utilized by D-I, NBA and WNBA coaching staffs, conference networks and media organizations.</p>}
        {userTier.value === 4 && <p style={{ ...accountTierStyles, marginTop: 15 }}>You must email {nickEmailLink} to receive a quote for a Pro Tier subscription. </p>}
        {userTier.value === 2 && <p style={{ margin: 0, marginTop: 15, lineHeight: 1.3, fontSize: '0.95em', color: 'darkgreen', fontWeight: 700 }}>
            Your subscription is {price.text}. {referral.discount > 0 && `A $${referral.discount / 100} discount will be applied to your subscription, and your card will not be charged until the discount has been fully utilized.`}
        </p>}
    </>);


    // Signup Error Alert Modals
    // ==========================
    const accountCreationErrorAlert = <Alert style={{ marginTop: 12 }} variant={'danger'}>{signupError.account}</Alert>;
    const emailVerifyErrorAlert = <Alert style={{ marginTop: 12 }} variant={'danger'}>{signupError.email}</Alert>;
    const stripeSubscribeErrorAlert = <Alert style={{ marginTop: 12 }} variant={'danger'}>{signupError.stripe}</Alert>;
    const catchAllSignupErrorAlert = <Alert style={{ marginTop: 12 }} variant={'danger'}>{signupError.other}</Alert>;


    // And Return Modal
    // =================

    // console.log('sendEmail: ', sendEmail);
    return (<>
        <HelmetComponent page='signupPage' />
        <Row style={{ margin: 0, marginBottom: -55, justifyContent: 'center', paddingTop: 25, paddingBottom: 85, background: '#EEE' }}>
            <Link to='/'>
                <img style={{ width: 240 }} alt='company logo' src={imageUrls.cbbLogoBlack} />
            </Link>
        </Row>

        <Container style={{ width: 'fit-content', marginBottom: 80, padding: 0, background: '#FFF', borderRadius: 20 }}>
            {!didSignUp &&
                <Container style={{ maxWidth: 450, boxShadow: '0 0 25px rgba(0,0,0,0.2)', borderRadius: 20, padding: 25, paddingBottom: 45 }}>
                    <Row style={{ margin: 0, marginBottom: 20, justifyContent: 'center' }}>
                        <p style={{ lineHeight: 1.25, fontWeight: 700, fontSize: '1.25em', color: '#0066CC' }}>
                            Creating New CBB Analytics Account
                        </p>
                    </Row>

                    {/* Section 1 - Account & User Info */}
                    <Row style={{ justifyContent: 'center', margin: 0, marginBottom: 12 }}>
                        {accountCredentialsText}
                    </Row>
                    <Row style={{ flexDirection: 'column', margin: '0px auto 40px', maxWidth: 320 }}>
                        {config.isDev && testStatesButton}
                        {isAdmin && adminGeneratePasswordForm}
                        {firstNameFormControl}
                        {lastNameFormControl}
                        {emailFormControl}
                        {passwordFormControl}
                        {verifyPasswordFormControl}
                        {userTypeSelect}
                        {userType && userType.value === 'other' && userTypeOtherFormControl}

                    </Row>

                    {/* Section 2 - League & Team Select */}
                    <Row style={{ margin: 0, marginBottom: 12 }}>
                        {teamConfSelectText}
                    </Row>
                    <Row style={{ flexDirection: 'column', margin: '0px auto 20px', maxWidth: 320 }}>
                        {leagueSelect}
                        {ncaaTeamSelect}
                    </Row>
                    <Row style={{ margin: '8px 0 0 0', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: 30, maxWidth: 375 }}>
                        {selectedConf && confLogo}
                        {selectedTeam && teamLogo}
                    </Row>

                    {/* Section 3 - Account Access */}
                    <Row style={{ margin: 0, marginBottom: 12 }}>
                        {accountTierText}
                    </Row>
                    <Row style={{ flexDirection: 'column', margin: '0px auto 20px', maxWidth: 320 }}>
                        {userSetTierSelect}
                        {isAdmin && sendEmailButton}
                        {userTier.value === 2 && !isAdmin && <>
                            {priceSelect}
                            {promoCodeInputForm}
                        </>}
                        {paymentForm}
                    </Row>
                    <Row style={{ margin: 0, marginBottom: 12, padding: '0px 15px', justifyContent: 'center' }}>
                        {accountTierInfoText}
                    </Row>

                    {/* Section 4 - here here here */}
                    <br />
                    <Row style={{ flexDirection: 'column', margin: '0px auto', maxWidth: 320 }}>
                        {signupButton}
                        {isSigningUp && <LoadingSpinner size='small' text='Signing Up...' />}
                    </Row>
                </Container>
            }

            {/* Alert: Free Tier Signup */}
            {didSignUp && sendEmail && <>
                <Row style={{ maxWidth: 450, margin: '0px auto' }}>
                    <div style={{ background: '#2e9e22', width: '100%', minHeight: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px 20px 0 0' }}>
                        <FaCheckCircle size='6em' color='white' />
                    </div>
                    <div style={{ background: '#2e9e22', textAlign: 'center', borderRadius: '0 0 20px 20px' }}>
                        <p style={{ color: '#F3F3F3', margin: 10, marginTop: -15, fontSize: '2.75em', fontWeight: 700 }}>
                            Success!
                        </p>
                        <p style={{ color: '#F3F3F3', margin: 25, fontSize: '1.2em' }}>
                            To make sure you're really you, we sent a verification email to your email address.
                        </p>
                        <p style={{ color: '#F3F3F3', margin: 25, fontSize: '1.2em', lineHeight: '1.4em' }}>
                            If you do not receive an email in your inbox, check your spam, social & promotional folders,
                            or <Link to={'/send-verification-email'} style={{ color: '#F3F3F3', textDecoration: 'underline', fontWeight: 700 }}>resend another verification email</Link>.
                            {userTier.value === 2 &&
                                ` The 1-week trial does not begin until your email address has been verified.`
                            }
                        </p>
                        <p style={{ color: '#F3F3F3', margin: 25, marginBottom: 60, fontSize: '1.2em' }}>
                            Email {nickEmailLink2} if you have any questions or need any further assistance.
                        </p>
                    </div>
                </Row>
            </>}

            {/* Handle Admin Signup, No Verification Email Sent */}
            {didSignUp && !sendEmail && <>
                <Row style={{ maxWidth: 600, margin: '25px auto 0px auto' }}>
                    <Alert variant='success'>
                        Sign up successful, no email verification sent. User is Tier {userTier.value}
                    </Alert>
                </Row>
            </>}

            {/* Stripe Signup Error */}
            {signupError.stripe && stripeSubscribeErrorAlert}
            {signupError.email && emailVerifyErrorAlert}
            {signupError.account && accountCreationErrorAlert}
            {signupError.other && catchAllSignupErrorAlert}
        </Container>
    </>);
}

export default SignupPage;
// 417 // 601 // 656 // 787 // 738
