
// position: 'relative' is required on <div> wrapping the <svg>

// imports
import React, { createContext, useRef, useState, useContext } from 'react';

const baseStyle = {
    backgroundColor: '#ffffff',
    boxShadow: `0px 2px 4px rgba(0,0,0, 0.18)`,
    height: 'auto',
    padding: 5,
    position: 'absolute',
    textAlign: 'left',
    width: 335
};

// tooltip context
export const TooltipContext = createContext({});
export const useTooltipContext = () => useContext(TooltipContext);

// grab the 'left', 'top' values for tooltip positioning
export const getTooltipLeftTop = ({ window, event }) => {
    // handle flipping tooltip when near right-side of screen
    const pageX = event.pageX;
    const isFlipped = window.innerWidth - pageX < 300 ? true : false;
    // grab x, y
    const offsetX = event.offsetX ?? event.nativeEvent?.offsetX ?? 0;
    const offsetY = event.offsetY ?? event.nativeEvent?.offsetY ?? 0;
    // set left, top
    const left = isFlipped ? offsetX - 316 : offsetX + 18;
    const top = offsetY + 20;

    return { left, top };
};

export const useTooltipState = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipCoords, setTooltipCoords] = useState({ left: 0, top: 0 });
    const [tooltipInfo, setTooltipInfo] = useState(null);
    const [colorScheme, setColorScheme] = useState({ background: '#fff', color: 'black', teamHex: null });

    // below code is so we don't get jittery tooltips when going from one hover state to another.
    // if we call delayHideTooltip onMouseOut, then cancelHideTooltip onMouseOver, we'll have a much
    // smoother transition
    const currentTimeoutId = useRef(null);
    const tooltipHideDelay = useRef(100);
    const delayHideTooltip = () => {
        const timeoutId = setTimeout(() => setShowTooltip(false), tooltipHideDelay.current);
        currentTimeoutId.current = timeoutId;
    };
    const cancelHideTooltip = () => {
        clearTimeout(currentTimeoutId.current);
    };
    const baseShowTooltip = ({ d, left, top }) => {
        // for some reason, cannot pass "event" as param to here, to compute left,top in here.
        cancelHideTooltip();
        setTooltipInfo(d);
        setShowTooltip(true);
        setTooltipCoords({ left, top });
    };


    // all stateful values & setting functions from the tooltip
    return {
        showTooltip,
        setShowTooltip,
        delayHideTooltip,
        cancelHideTooltip,
        tooltipInfo,
        tooltipHideDelay,
        setTooltipInfo,
        colorScheme,
        setColorScheme,
        baseShowTooltip,
        tooltipCoords,
        setTooltipCoords
    };
};

function Tooltip({
    renderer, // the html from getTooltipHtml()
    tooltipState, // the tooltip state
    overrideStyles = {} // styles to add
}) {
    const { colorScheme, tooltipInfo, tooltipCoords } = tooltipState; // tooltipInfo, elementD
    const { background, color } = colorScheme;
    const { left, top } = tooltipCoords;
    const colorClass = color === '#111' ? 'black' : 'white';
    const tooltipRef = useRef(null);

    // And Return
    return (
        <div
            ref={tooltipRef}
            className={`cbb-tooltip ${colorClass}`} // remove this?
            style={{
                ...baseStyle,
                ...(colorScheme.teamHex && { borderRight: `5px solid ${colorScheme.teamHex}` }),
                left,
                top,
                background,
                color,
                zIndex: 10,
                ...overrideStyles
            }}
        >
            {renderer(tooltipInfo)}
        </div>
    );
}

export default Tooltip;
