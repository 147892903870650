
// This is bad, outdated + unused... Consider improving or removing...

// Import React Components
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Import CBB Components
import { Container } from 'react-bootstrap';
import HelmetComponent from '../../utils/HelmetUtils';

// Create The Component
class HelpPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        // The Return
        return (
            <React.Fragment>
                <HelmetComponent page="helpPage" />
                <Container className='shadow-container' style={{ maxWidth: '1000px' }}>
                    {/* <PageHeader label='Help & FAQ' /> got rid of this... */}

                    Data and Analytics
                    - We have API access to a leading Sports data provider, and obtain the best and most accurate college basketball statistics, boxscores, and play-by-play feeds that are available.
                    - We compute percentiles or rankings to complement all of the statistics calculated on the website. These ratings provide an added context to the website's statistics, which allows for better inferences on the stats to be made.
                    - All of the Lineup and On/Off statistics, as well as Shooting by Region (by distance and by zone) are computed from play-by-play data.
                    - Unfortunately, play-by-play data is not available for every single game. Some games track play by play, some games track players on court. We estimate on court when we have pbp but no on court.

                    Shot Charts
                    - Shot Charts are our bread and butter. From our outset, we have been driven to create the very best, most intuitive and analytical shot charts. Motivated by previous work done by Kirk Goldsberry, we are proud of our shot charts.
                    - Heatmaps
                        - 3 scopes for our heatmaps are players, team (offensive) and team (defensive)
                    - Shooting by Region
                        - in addition to offensive and defensive, we also break down field goal percentages as well as field goal attempt frequencies.
                    - Marker Graphs
                        - less exciting, currently used only for single game recaps.

                    Statistical Tables
                    - We have 6 scopes for our statistical tables:
                        - Player-Season, Player-Game, Team-Season, Team-Game, Lineup, On/Off
                    - Each scope has slightly different hierarchies, although for the most part they are the same.
                    - Every Table has tooltips with.
                    -

                </Container>
            </React.Fragment>
        );
    }
}

export default HelpPage;
