
import React from 'react';
import { timeFormat } from 'd3-time-format';

function AxisBottom({
    xScale,
    setStyleName = () => {},
    styleName = 'default',
    height,
    width,
    label = '',
    count = 10,
    unit = 'num',
    showGrid = false,
    linearScale = true,
    labels,
    override = {}, // { textFill, tickSize, labelSize }
    minify = false // if true, show title in bottom-left corner only
}) {
    // Grab, Set Constants
    // ====================
    const axisToGraphPadding = 10;
    const labelToAxisPadding = 10;
    const textFill = override.textFill || ['dark', 'grey'].includes(styleName) ? '#ddd' : '#444';
    const tickSize = override.tickSize || 15;
    const labelSize = override.labelSize || 17;


    // Linear Scale Axis (Ticks + Grid) (90% of the time)
    // ===================================================
    const axisLinear = linearScale && xScale.ticks(count).map((d, i) => {
        let axisText;
        switch (unit) {
            case 'gameDate': axisText = `${d.getMonth() + 1}/${d.getDate()}`; break;
            case 'monthYear': axisText = timeFormat('%m/%y')(d); break;
            case 'pct': axisText = `${(100 * d).toFixed(0)}%`; break;
            case 'int': axisText = d; break;
            case 'num': axisText = d; break;
            case 'num2': axisText = `${d.toFixed(2)}`; break;
            case 'ratio': axisText = `${d.toFixed(2)}x`; break;
            case '$100k': axisText = d >= 995000
                ? `$${(d / 1000000).toFixed(2)}M`
                : `$${(d / 10000).toFixed(0) * 10}K`; break;
            case '100k': axisText = d >= 995000
                ? `${(d / 1000000).toFixed(2)}M`
                : `${(d / 10000).toFixed(0) * 10}K`; break;
            default: axisText = 'okay';
        }
        return (
            <g className='x-tick' key={i}>
                {showGrid === true &&
                    <line
                        style={{ stroke: 'rgba(0, 0, 0, 0.08)', strokeWidth: 0.6 }}
                        x1={xScale(d)}
                        x2={xScale(d)}
                        y1={40}
                        y2={height}
                    />
                }
                <text
                    style={{ textAnchor: 'middle', fontSize: tickSize, fill: textFill }}
                    dy='.71em'
                    x={xScale(d)}
                    y={height + axisToGraphPadding}
                >
                    {axisText}
                </text>
            </g>
        );
    });


    // Non-Linear Scale Axis (Ticks + Grid)
    // =====================================
    const axisScaleBand = !linearScale && labels.map((d, i) => {
        return (
            <g className='x-tick' key={i}>
                {showGrid === true &&
                    <line
                        style={{ stroke: 'rgba(0, 0, 0, 0.08)', strokeWidth: 0.6 }}
                        x1={(xScale(d) + xScale.bandwidth(d) / 2)}
                        x2={(xScale(d) + xScale.bandwidth(d) / 2)}
                        y1={5}
                        y2={height - 5}
                    />
                }
                <text
                    style={{ textAnchor: 'middle', fontSize: tickSize, fill: textFill }}
                    dy='.71em'
                    x={(xScale(d) + xScale.bandwidth(d) / 2)}
                    y={height + axisToGraphPadding}
                >
                    {d}
                </text>
            </g>
        );
    });

    // Axis Title
    // ============
    let axisTitle =
        (<g className='x-label'>
            <text
                onClick={setStyleName}
                style={{ textAnchor: 'middle', fontSize: labelSize, fontWeight: 700, fill: textFill }}
                dy='2.0em'
                x={width / 2}
                y={height + labelToAxisPadding}
            >
                {label}
            </text>
        </g>);

    let axisTitleSmallInCorner =
        (<g className='x-label'>
            <text
                onClick={setStyleName}
                style={{ fontSize: labelSize - 4, fontWeight: 700, fill: textFill }}
                x={0}
                y={height + labelToAxisPadding}
            >
                {label}
            </text>
        </g>);


    // And Return
    // ===========
    return (<>
        {/* Standard Axis */}
        {!minify &&
            <g className='x-axis'>
                {axisTitle}
                {linearScale && axisLinear}
                {!linearScale && axisScaleBand}
            </g>
        }

        {/* Minified Axis (small text in corner only) */}
        {minify &&
            <g className='x-axis'>
                {axisTitleSmallInCorner}
            </g>
        }
    </>);
}

export default AxisBottom;
