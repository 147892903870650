
// Top 5 Conferences In Each Division (Not Splitting By Gender or Year)
// In the future, this type of info should perhaps be saved to a users account
const validCompetitionIds = [22850, 22853, 24996, 24997, 27693, 27694, 30629, 30630, 33533, 33535, 36046, 36045, 38409, 38529];
const allCompetitionIds = {
    all: [22850, 22853, 24996, 24997, 27693, 27694, 30629, 30630, 33533, 33535, 36046, 36045, 38409, 38529],
    male: [22850, 24996, 27693, 30629, 33533, 36046, 38409],
    female: [22853, 24997, 27694, 30630, 33535, 36045, 38529],
    d1New3: [27693, 24996, 30629, 30630, 33533, 33535, 36046, 36045, 38409, 38529], // D-I for mens starting 2019-2020, womens starting 2021-2022
    d2New3: [30629, 30630, 33533, 33535, 36046, 36045, 38409, 38529], // D-II, III starting 2021-2022
    draft: [27693, 27694, 30629, 30630, 33533, 33535, 36045, 36046, 38409, 38529], // Seasons where we've hardcoded selection of draft players
    portal: [36045, 36046],
    nilPredictions: [36046, 38409],
    maleLatest: 38409,
    femaleLatest: 38529
};
const topD1Conferences = [
    { conferenceId: 53, conferenceLongName: 'Atlantic Coast Conference' },
    { conferenceId: 89, conferenceLongName: 'Southeastern Conference' },
    { conferenceId: 60, conferenceLongName: 'Big 12 Conference' },
    { conferenceId: 73, conferenceLongName: 'Big Ten Conference' },
    { conferenceId: 29, conferenceLongName: 'Big East Conference' },
    { conferenceId: 74, conferenceLongName: 'Pac-12 Conference' }
];
const topD2Conferences = [
    { conferenceId: 27, conferenceLongName: 'Pennsylvania State Athletic Conference' },
    { conferenceId: 49, conferenceLongName: 'Great Lakes Valley Conference' },
    { conferenceId: 71, conferenceLongName: 'Northeast-10 Conference' },
    { conferenceId: 70, conferenceLongName: 'Northern Sun Intercollegiate Conference' },
    { conferenceId: 83, conferenceLongName: 'Mid-America Intercollegiate Athletics Association' }
];
const topD3Conferences = [
    { conferenceId: 78, conferenceLongName: 'New England Small College Athletic Conference' },
    { conferenceId: 66, conferenceLongName: 'Old Dominion Athletic Conf.' },
    { conferenceId: 24, conferenceLongName: 'College Conference of Illinois & Wisconsin' },
    { conferenceId: 34, conferenceLongName: 'State University of New York Athletic Conference' },
    { conferenceId: 98, conferenceLongName: 'Southern California Intercollegiate Athletic Conf.' }
];
const topConferences = {
    1: topD1Conferences,
    2: topD2Conferences,
    3: topD3Conferences
};

// Top Teams For Shot Charts (computing as table in BQ, but having defaults for initial fetches helps)
let topTeamsForShotCharts = {
    24996: {
        1: [
            { value: 103765, label: 'M: Kentucky Wildcats' },
            { value: 103549, label: 'M: Duke Blue Devils' },
            { value: 103903, label: 'M: Michigan St. Spartans' },
            { value: 103296, label: 'M: Arizona Wildcats' },
            { value: 103757, label: 'M: Kansas Jayhawks' },
            { value: 103257, label: 'M: Purdue Boilermakers' }]
    },
    24997: {
        1: [
            { value: 105319, label: 'W: Oregon Ducks' },
            { value: 104607, label: 'W: Baylor Lady Bears' },
            { value: 105633, label: 'W: UConn Huskies' },
            { value: 105491, label: 'W: South Carolina Gamecocks' },
            { value: 105556, label: 'W: Stanford Cardinal' },
            { value: 105587, label: 'W: Texas Longhorns' }]
    }
};

// mens
topTeamsForShotCharts[22850] = topTeamsForShotCharts[24996];
topTeamsForShotCharts[27693] = topTeamsForShotCharts[24996];
topTeamsForShotCharts[30629] = topTeamsForShotCharts[24996];
topTeamsForShotCharts[33533] = topTeamsForShotCharts[24996];
topTeamsForShotCharts[36046] = topTeamsForShotCharts[24996];
topTeamsForShotCharts[38409] = topTeamsForShotCharts[24996];

// womens
topTeamsForShotCharts[22853] = topTeamsForShotCharts[24997];
topTeamsForShotCharts[27694] = topTeamsForShotCharts[24997];
topTeamsForShotCharts[30630] = topTeamsForShotCharts[24997];
topTeamsForShotCharts[33535] = topTeamsForShotCharts[24997];
topTeamsForShotCharts[36045] = topTeamsForShotCharts[24997];
topTeamsForShotCharts[38529] = topTeamsForShotCharts[24997];

// competitionId to abb1, abb2, abb3, obj map
const competitionIdMap = {
    22850: { abb1: '18-19', abb2: '2018-19', abb3: '2018-2019', priorId: null, nextId: 24996, yearStart: 2018, yearEnd: 2019, obj: { gender: 'MALE', value: 22850, label: `2018-19 Men's Basketball`, shortLabel: 'M1819' } },
    22853: { abb1: '18-19', abb2: '2018-19', abb3: '2018-2019', priorId: null, nextId: 24997, yearStart: 2018, yearEnd: 2019, obj: { gender: 'FEMALE', value: 22853, label: `2018-19 Women's Basketball`, shortLabel: 'W1819' } },
    24996: { abb1: '19-20', abb2: '2019-20', abb3: '2019-2020', priorId: 22850, nextId: 27693, yearStart: 2019, yearEnd: 2020, obj: { gender: 'MALE', value: 24996, label: `2019-20 Men's Basketball`, shortLabel: 'M1920' } },
    24997: { abb1: '19-20', abb2: '2019-20', abb3: '2019-2020', priorId: 22853, nextId: 27694, yearStart: 2019, yearEnd: 2020, obj: { gender: 'FEMALE', value: 24997, label: `2019-20 Women's Basketball`, shortLabel: 'W1920' } },
    27693: { abb1: '20-21', abb2: '2020-21', abb3: '2020-2021', priorId: 24996, nextId: 30629, yearStart: 2020, yearEnd: 2021, obj: { gender: 'MALE', value: 27693, label: `2020-21 Men's Basketball`, shortLabel: 'M2021' } },
    27694: { abb1: '20-21', abb2: '2020-21', abb3: '2020-2021', priorId: 24997, nextId: 30630, yearStart: 2020, yearEnd: 2021, obj: { gender: 'FEMALE', value: 27694, label: `2020-21 Women's Basketball`, shortLabel: 'W2021' } },
    30629: { abb1: '21-22', abb2: '2021-22', abb3: '2021-2022', priorId: 27693, nextId: 33533, yearStart: 2021, yearEnd: 2022, obj: { gender: 'MALE', value: 30629, label: `2021-22 Men's Basketball`, shortLabel: 'M2122' } },
    30630: { abb1: '21-22', abb2: '2021-22', abb3: '2021-2022', priorId: 27694, nextId: 33535, yearStart: 2021, yearEnd: 2022, obj: { gender: 'FEMALE', value: 30630, label: `2021-22 Women's Basketball`, shortLabel: 'W2122' } },
    33533: { abb1: '22-23', abb2: '2022-23', abb3: '2022-2023', priorId: 30629, nextId: 36046, yearStart: 2022, yearEnd: 2023, obj: { gender: 'MALE', value: 33533, label: `2022-23 Men's Basketball`, shortLabel: 'M2223' } },
    33535: { abb1: '22-23', abb2: '2022-23', abb3: '2022-2023', priorId: 30630, nextId: 36045, yearStart: 2022, yearEnd: 2023, obj: { gender: 'FEMALE', value: 33535, label: `2022-23 Women's Basketball`, shortLabel: 'W2223' } },
    36046: { abb1: '23-24', abb2: '2023-24', abb3: '2023-2024', priorId: 33533, nextId: 38409, yearStart: 2023, yearEnd: 2024, obj: { gender: 'MALE', value: 36046, label: `2023-24 Men's Basketball`, shortLabel: 'M2324' } },
    36045: { abb1: '23-24', abb2: '2023-24', abb3: '2023-2024', priorId: 33535, nextId: 38529, yearStart: 2023, yearEnd: 2024, obj: { gender: 'FEMALE', value: 36045, label: `2023-24 Women's Basketball`, shortLabel: 'W2324' } },
    38409: { abb1: '24-25', abb2: '2024-25', abb3: '2024-2025', priorId: 36046, nextId: null, yearStart: 2024, yearEnd: 2025, obj: { gender: 'MALE', value: 38409, label: `2024-25 Men's Basketball`, shortLabel: 'M2425' } },
    38529: { abb1: '24-25', abb2: '2024-25', abb3: '2024-2025', priorId: 36045, nextId: null, yearStart: 2024, yearEnd: 2025, obj: { gender: 'FEMALE', value: 38529, label: `2024-25 Women's Basketball`, shortLabel: 'W2425' } }
};

const competitionIdArray = [
    { gender: 'MALE', value: 22850, label: `2018-19 Men's Basketball`, shortLabel: 'M1819', abb1: '18-19', abb2: '2018-19', abb3: '2018-2019', priorId: null, nextId: 24996 },
    { gender: 'FEMALE', value: 22853, label: `2018-19 Women's Basketball`, shortLabel: 'W1819', abb1: '18-19', abb2: '2018-19', abb3: '2018-2019', priorId: null, nextId: 24997 },
    { gender: 'MALE', value: 24996, label: `2019-20 Men's Basketball`, shortLabel: 'M1920', abb1: '19-20', abb2: '2019-20', abb3: '2019-2020', priorId: 22850, nextId: 27693 },
    { gender: 'FEMALE', value: 24997, label: `2019-20 Women's Basketball`, shortLabel: 'W1920', abb1: '19-20', abb2: '2019-20', abb3: '2019-2020', priorId: 22853, nextId: 27694 },
    { gender: 'MALE', value: 27693, label: `2020-21 Men's Basketball`, shortLabel: 'M2021', abb1: '20-21', abb2: '2020-21', abb3: '2020-2021', priorId: 24996, nextId: 30629 },
    { gender: 'FEMALE', value: 27694, label: `2020-21 Women's Basketball`, shortLabel: 'W2021', abb1: '20-21', abb2: '2020-21', abb3: '2020-2021', priorId: 24997, nextId: 30630 },
    { gender: 'MALE', value: 30629, label: `2021-22 Men's Basketball`, shortLabel: 'M2122', abb1: '21-22', abb2: '2021-22', abb3: '2021-2022', priorId: 27693, nextId: 33533 },
    { gender: 'FEMALE', value: 30630, label: `2021-22 Women's Basketball`, shortLabel: 'W2122', abb1: '21-22', abb2: '2021-22', abb3: '2021-2022', priorId: 27694, nextId: 33535 },
    { gender: 'MALE', value: 33533, label: `2022-23 Men's Basketball`, shortLabel: 'M2223', abb1: '22-23', abb2: '2022-23', abb3: '2022-2023', priorId: 30629, nextId: 36046 },
    { gender: 'FEMALE', value: 33535, label: `2022-23 Women's Basketball`, shortLabel: 'W2223', abb1: '22-23', abb2: '2022-23', abb3: '2022-2023', priorId: 30630, nextId: 36045 },
    { gender: 'MALE', value: 36046, label: `2023-24 Men's Basketball`, shortLabel: 'M2324', abb1: '23-24', abb2: '2023-24', abb3: '2023-2024', priorId: 33533, nextId: 38409 },
    { gender: 'FEMALE', value: 36045, label: `2023-24 Women's Basketball`, shortLabel: 'W2324', abb1: '23-24', abb2: '2023-24', abb3: '2023-2024', priorId: 33535, nextId: 38529 },
    { gender: 'MALE', value: 38409, label: `2024-25 Men's Basketball`, shortLabel: 'M2425', abb1: '24-25', abb2: '2024-25', abb3: '2024-2025', priorId: 36046, nextId: null },
    { gender: 'FEMALE', value: 38529, label: `2024-25 Women's Basketball`, shortLabel: 'W2425', abb1: '24-25', abb2: '2024-25', abb3: '2024-2025', priorId: 36045, nextId: null }
];


// let competitionInfo =
module.exports = {
    competitionIdMap,
    competitionIdArray,
    allCompetitionIds,
    validCompetitionIds,
    topD1Conferences,
    topD2Conferences,
    topD3Conferences,
    topConferences,
    topTeamsForShotCharts
};
